function Slider() {
    return (
        <>
            <div className="video-background">
                <video autoPlay muted loop>
                    <source src="https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/website/awani/awani.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </>
    );
}

export default Slider;