import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

function Footer({language, direction}) {
  return (
    <>
      <div className="sticky-bottom" dir={direction}>
        <div className="footer py-1">
          <Container className="d-flex justify-content-between">
            <div className="text-uppercase text-light fs-7 my-auto">© 2023 awani.ae</div>
            <div className="d-flex justify-content-between">
              <div className="text-uppercase mx-2 text-light fs-7 my-auto">{language === "AR" ? 'تابعنا' : 'Follow us'}</div>
              <div className="mx-2">
                <a href="https://www.instagram.com/awaniuae/" target="blank">
                  <Image src="https://awjftp.blob.core.windows.net/awjcontents/websites/awani/instagram.webp" className="social-icons" />
                </a>
              </div>
              <div className="mx-2">
                <a href="https://www.facebook.com/AwaniUAE/" target="blank">
                  <Image src="https://awjftp.blob.core.windows.net/awjcontents/websites/awani/facebook.webp" className="social-icons" />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Footer;