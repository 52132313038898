import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function Section5({ data,language, direction }) {
    return (
        <div id="section5" className="section5-bg scroll-div scroll-mt" dir={direction}>
            <div className="text-center">
                <h1 className={"secondary"+language}>{language === 'AR' ? 'مواقعنا' : 'Visit Our Space'}</h1>
            </div>
            <Container className="section5 px-section px-0">
                <div className="text-center pt-4 pb-5">
                    <h4 className={"secondary"+language}>{language === 'AR' ? 'الإمارات العربية المتحدة' : 'United Arab Emirates'}</h4>
                </div>
                <Row className="w-100">
                    <Col className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'دبي مول' : data[0].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[0].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'دبي مول – وسط مدينة دبي' :data[0].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأثنين إلى الخميس : الساعه 10 صباحاً إلى 12 صباحأً' : data[0].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'الجمعة إلى الأحد : 10 صباحاً إلى 1 صباحاً' : data[0].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+ 971 4 243 2758</p>
                        <a target="blank" href="tel:0097142432758" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/8GeGgNiqJb8DxwwEA" className={"d-block text-uppercase link-offset-3 mobile-location-fs mt-2 danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                    <div className="vr p-0 location-separator"></div>
                    <Col className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'جي بي أر (ذا بيتش) ' :data[1].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[1].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'جي بي أر (ذا بيتش) – مساكن شاطئ جميرا' : data[1].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأثنين إلى الخميس : الساعه 10 صباحاً إلى 1 صباحأً' : data[1].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'الجمعة إلى الأحد : 10 صباحاً إلى 2 صباحاً   ' : data[1].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+971 4 424 8082</p>
                        <a target="blank" href="tel:0097144248082" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/DVbna2A5vvNDazrU7" className={"d-block text-uppercase link-offset-3 mobile-location-fs danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                </Row>
                <Row className="w-100 pt-5">
                    <Col xs={6} className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'دبي هيلز مول' : data[6].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[6].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'دبي هيلز مول' :data[6].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأثنين إلى الخميس : الساعه 12 ظهراً إلى 12 صباحأً' : data[6].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'الجمعة إلى الأحد : 10 صباحاً إلى 1 صباحاً' : data[6].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+ 971 4 852 3763</p>
                        <a target="blank" href="tel:0097148523763" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/NK5HUZxJX1YXSwEX9" className={"d-block text-uppercase link-offset-3 mobile-location-fs mt-2 danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                    <div className="vr p-0 location-separator"></div>
                    <Col className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'دبي مارينا مول' :data[7].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[7].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'دبي مارينا مول' : data[7].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأثنين إلى الخميس : الساعه 9 صباحاً إلى 12 صباحأً' : data[7].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'الجمعة إلى الأحد : 9 صباحاً إلى 1 صباحاً   ' : data[7].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+971 4 289 0715</p>
                        <a target="blank" href="tel:0097142890715" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/Laa6cBb4j31yGMjv7" className={"d-block text-uppercase link-offset-3 mobile-location-fs danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                </Row>
                <Row className="w-100 pt-5">
                    <Col xs={6} className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'سوق السهول' : data[8].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[8].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'سوق السهول' :data[8].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأثنين إلى الجمعة : الساعه 8 صباحاً إلى 12 صباحأً' : data[8].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'السبت إلى الأحد : 8 صباحاً إلى 1 صباحاً' : data[8].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+971 4 557 3197</p>
                        <a target="blank" href="tel:0097145573197" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/PWCZnhBHx1iY6wPQ6" className={"d-block text-uppercase link-offset-3 mobile-location-fs mt-2 danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                    <div className="vr p-0 location-separator"></div>
                </Row>
                <div className="text-center pt-5">
                    <h4 className={"secondary"+language}>{language === 'AR' ? 'المملكة العربية السعودية' : 'Saudi Arabia'}</h4>
                </div>
                <Row className="w-100">
                    <Col className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'ذا زون' : data[2].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[2].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'مجمع ذا زون – التخصصي – الرياض' : data[2].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأحد إلى الأربعاء : الساعة 9 صباحاً إلى 1 صباحاً' : data[2].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'الخميس إلى السبت : 9 صباحاً إلى 2 صباحاً' : data[2].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+966 011 422 2294</p>
                        <a target="blank" href="tel:00966114222294" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/ftaQTuBzUxnV6oxe6" className={"d-block text-uppercase link-offset-3 mobile-location-fs danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                    <div className="vr p-0 location-separator"></div>
                    <Col className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'نخيل مول' : data[3].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[3].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'نخيل مول – المغرزات – الرياض' : data[3].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأحد إلى الأربعاء : الساعه 9 صباحاً إلى 12 صباحأً' : data[3].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'الخميس إلى السبت : 9 صباحاً إلى 1 صباحاً' : data[3].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+966 011 622 2252</p>
                        <a target="blank" href="tel:00966116222252" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/1yNTLvdWP5cYrGXZA" className={"d-block text-uppercase link-offset-3 mobile-location-fs danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                </Row>
                <Row className="w-100 pt-5">
                    <Col className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'التحلية' : data[4].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[4].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'طريق محمد بن عبدالعزيز – العليا – الرياض' : data[4].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأحد إلى الأربعاء : 9 صباحاً إلى 1 صباحاً' : data[4].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75 mb-3">{language === 'AR' ? 'الخميس إلى السبت : 9 صباحاً على 3 صباحاً' : data[4].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+966 58 0222138</p>
                        <a target="blank" href="tel:00966580222138" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/jNoo4ucrM2TRWZNV8" className={"text-uppercase link-offset-3 mobile-location-fs danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                    <div className="vr p-0 location-separator"></div>
                    <Col className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'واجهة روشن' : data[5].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[5].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'مول الواجهة – طريق المطار الملك خالد – رياض' : data[5].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأحد إلى الأربعاء : 9 صباحاً إلى 1 صباحاً' : data[5].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75 mb-3">{language === 'AR' ? 'الخميس إلى السبت : 9 صباحاً على 3 صباحاً' : data[4].opening_hours.split(',')[1]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75 mb-3">{language === 'AR' ? '' : data[5].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+966 011 446 2228</p>
                        <a target="blank" href="tel:009660114462228" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/PGJseVPD2uowAUau6" className={"text-uppercase link-offset-3 mobile-location-fs danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                </Row>
                <Row className="w-100 pt-6">
                    <Col  xs={6} className={"text-center primary"+language} data-aos="fade-up" data-aos-duration="3000">
                        <span className={"d-block fw-bold mb-1 info"+language}>{language === 'AR' ? 'يو ووك جدة' : data[9].name}</span>
                        <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data[9].image} className="location-img" />
                        <p className="my-1 mt-3 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'يو ووك جدة' : data[9].address}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'ساعات العمل:' : 'Opening Hours:'}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75">{language === 'AR' ? 'من الأحد إلى الأربعاء : 9 صباحاً إلى 1 صباحاً' : data[9].opening_hours.split(',')[0]}</p>
                        <p className="my-1 mobile-location-fs mx-auto w-75 mb-3">{language === 'AR' ? 'الخميس إلى السبت : 9 صباحاً على 2 صباحاً' : data[9].opening_hours.split(',')[1]}</p>
                        <p className="d-desktop my-1 mobile-location-fs mx-auto w-75 mb-3" dir='ltr'>+966 12 423 3484</p>
                        <a target="blank" href="tel:00966124233484" className={"d-mobile text-uppercase link-offset-3 mobile-location-fs mb-2 info"+language}>{language === 'AR' ? 'اتصل بنا' : 'Contact Us'}</a>
                        <a target="blank" href="https://maps.app.goo.gl/WaKPLyyWHywK4vD76" className={"text-uppercase link-offset-3 mobile-location-fs danger"+language}>{language === 'AR' ? 'الموقع' : 'Get directions'}</a>
                    </Col>
                    <div className="vr p-0 location-separator"></div>
                </Row>
            </Container>
        </div>
    );
}
export default Section5;