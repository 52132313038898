import Image from 'react-bootstrap/Image'

function Section1({data,language, direction}) {
    return (
        <div id="section1" className="section1 px-lg-section scroll-div scroll-mt d-flex justify-content-between text-center pt-5" dir={direction}>
            <div className="px-4">
                <h1 className={"secondary"+language}>{language === 'AR' ? 'حيث يلتقي عالم النكهات بالتقاليد' : data.title}</h1>
                <p className={"py-3 primary"+language}>{language === 'AR' ? 'أواني، وجهة باهرة لمأكولات أصيلة، تراثية، وغنيّة من منطقة الشرق الأوسط، بما في ذلك سوريا، لبنان، الأردن وفلسطين. التفاني الذي لا يتزعزع لدينا في التميّز يضمن تجربة طعام فريدة من نوعها، حيث يتّم صنع كل طبق بعناية فائقة يومياً، باستخدام أجود المكونات الطازجة.الشرق الأوسط، الذي ُيشكِّل لوحة فنية من الثقافة والتاريخ، هو إلهامنا اليومي لأطباق شهيّة، مقدّمة عبر رحلة من الزمن والتقاليد من خلال قائمة طعام استثنائية ووافية للجودة في آنٍ واحدٍ.' : data.description}</p>
                <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+data.image} className="w-100" />
            </div>
        </div>
    );
}
export default Section1;