import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

function Section2({ data, images,language, direction }) {
    function openurl(url) {
        window.open(url, '_blank')
    }
    return (
        <div className="section2-bg" dir={direction}>
            <Container id="section2" className="section2 scroll-div scroll-mt px-section px-0">
                <div className="text-center pt-5">
                    <div className="px-4">
                        <h1 className={"secondary"+language}>{language === 'AR' ? 'قائمة طعامنا' : data.title}</h1>
                        <p className={"py-3 primary"+language}>{language === 'AR' ? 'قائمتنا تحتفي بالمطبخ الشرق أوسطي، وهي نسيج غني بالتاريخ والتقاليد القديمة. باستخدام الأدوات والمكونات التقليدية، نصنع أطباقًا من الماضي لخلق تقليد دائم. نحمل إرث هذه النكهات المألوفة في تجارب تناول الطعام المعاصرة لدينا.استمتع بمذاق الشرق الأوسط من خلال قائمتنا، حيث تتجمع التوابل والمكونات من فلسطين والأردن وسوريا ولبنان لتخلق تجارب طعام لا تُنسى.' : data.description}</p>
                    </div>
                </div>
                <div className="d-flex justify-content-between mobile-mx-1">
                    {
                        images.map((item) => (
                            <Image src={"https://sqlvas774pizbiy4km.blob.core.windows.net/liveenvironment/"+item.image} className="section2-img" />
                        ))
                    }
                </div>
                <div className="text-center py-5">
                    <Button variant="primary" className="rounded-pill text-uppercase mobile-btn primary-bg" onClick={() => openurl(data.foodLink+'?lang='+language)}>{language === 'AR' ? 'قائمة الطعام' : 'View Menu'}</Button>
                </div>
            </Container>
        </div>
    );
}
export default Section2;