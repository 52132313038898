import React, { useState, useEffect, useRef } from 'react'
import './design'
import './scripts'
/*React toastify library for notifications*/
import { ToastContainer, toast } from 'react-toastify'
import Placeholder from 'react-bootstrap/Placeholder'
import Header from './components/config/header'
import Footer from './components/config/footer'
import Slider from './components/sliders'
// import Alert from './components/alert'
import Section1 from './components/section1'
import Section2 from './components/section2'
import Section3 from './components/section3'
//import Section4 from './components/section4'
import Section5 from './components/section5'
import Section6 from './components/section6'

function App() {
  const [language, setLanguage] = useState("EN")
  const [direction, setDirection] = useState("ltr")
  // const [loadingSection4, setLoadingSection4] = useState(true)
  // const [outlets, setOutlets] = useState(false)
  const [loadingSlider, setLoadingSlider] = useState(true)
  const dataRefSlider = useRef([])
  const [loadingSection1, setLoadingSection1] = useState(true)
  const dataRefSection1 = useRef([])
  const [loadingSection2, setLoadingSection2] = useState(true)
  const dataRefSection2 = useRef([])
  const dataRefSection21 = useRef([])
  const [loadingSection3, setLoadingSection3] = useState(true)
  const dataRefSection3 = useRef([])
  const dataRefSection31 = useRef([])
  const [loadingSection5, setLoadingSection5] = useState(true)
  const dataRefSection5 = useRef([])
  const notify = (MSG) => toast(MSG); //Function to show system's notification
  const warning = (MSG) => toast.error(MSG); //Function to show system's error notification
  useEffect(() => {

    // Get the current URL
    const currentUrl = window.location.href;
    var url = new URL(currentUrl);
    var lang = url.searchParams.get("lang");
    if(lang) setLanguage(lang)
    if (lang === "AR") setDirection("rtl")
    else setDirection("ltr")
    // Find the index of ".ae"
    const dotAeIndex = currentUrl.indexOf('.ae');

    if (dotAeIndex !== -1) {
      // Extract everything after ".ae"
      const afterDotAe = currentUrl.substring(dotAeIndex + 4);

      // Check if it's equal to "digmenus/ksa"
      if (afterDotAe === 'digmenus/ksa') {
        // Redirect to another URL (e.g., https://example.com)
        window.location.href = 'https://black-ground-060f59203.3.azurestaticapps.net/?cnt=KSA';
      }
    }

    fetch('https://www-awani.azurewebsites.net/slider/all-slider')
      .then(response => response.json())
      .then(dataSlider => {
        dataRefSlider.current = dataSlider;
        setLoadingSlider(false);
      })
      .catch(error => console.log(error))
    fetch('https://www-awani.azurewebsites.net/about')
      .then(response => response.json())
      .then(dataSection1 => {
        dataRefSection1.current = dataSection1;
        setLoadingSection1(false);
      })
      .catch(error => console.log(error))
    fetch('https://www-awani.azurewebsites.net/about')
      .then(response => response.json())
      .then(dataSection1 => {
        dataRefSection1.current = dataSection1;
        setLoadingSection1(false);
      })
      .catch(error => console.log(error))
    fetch('https://www-awani.azurewebsites.net/our-menu')
      .then(response => response.json())
      .then(dataSection2 => {
        dataRefSection2.current = dataSection2;
        fetch('https://www-awani.azurewebsites.net/menu-images/all-menu-images')
          .then(response => response.json())
          .then(dataSection21 => {
            dataRefSection21.current = dataSection21;
            setLoadingSection2(false);
          })
          .catch(error => console.log(error))
      })
      .catch(error => console.log(error))
    fetch('https://www-awani.azurewebsites.net/event-section')
      .then(response => response.json())
      .then(dataSection3 => {
        dataRefSection3.current = dataSection3;
        fetch('https://www-awani.azurewebsites.net/event/all-event')
          .then(response => response.json())
          .then(dataSection31 => {
            dataRefSection31.current = dataSection31;
            setLoadingSection3(false);
          })
          .catch(error => console.log(error))
      })
      .catch(error => console.log(error))

    fetch('https://www-awani.azurewebsites.net/outlet/all-outlets')
      .then(response => response.json())
      .then(dataSection5 => {
        dataRefSection5.current = dataSection5;
        setLoadingSection5(false);
      })
      .catch(error => console.log(error))
    var hash = window.location.hash.substring(1)
    if (hash) {
      setTimeout(function () {
        var element = document.getElementById(hash);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 1000)
    }
    // fetch('https://www-awani.azurewebsites.net/event/all-event')
    //   .then(response => response.json())
    //   .then(data => {
    //     setOutlets(data.body)
    //     setLoadingSection4(false)
    //   })
    //   .catch(error => console.log(error))
  }, []);

  function handleLanguage(lang) {
    setLanguage(lang)
    if (lang === "AR") setDirection("rtl")
    else setDirection("ltr")
  }

  return (
    <div className="App">
      <Header handleLanguage={handleLanguage} language={language} direction={direction}/>
      {
        // loadingSlider ? (<div><Placeholder xs={12} size="lg" animation="glow" /></div>) : (<Slider data={dataRefSlider.current.body} />)
        loadingSlider ? (<div><Placeholder xs={12} size="lg" animation="glow" /></div>) : (<Slider />)
      }
      {
        loadingSection1 ? (<div><Placeholder xs={12} size="lg" animation="glow" /></div>) : (<Section1 data={dataRefSection1.current.body} language={language} direction={direction}/>)
      }
      {
        loadingSection2 ? (<div><Placeholder xs={12} size="lg" animation="glow" /></div>) : (<Section2 data={dataRefSection2.current.body} images={dataRefSection21.current.body} language={language} direction={direction}/>)
      }
      <div className="section3-bg">
        {
          loadingSection3 ? (<div><Placeholder xs={12} size="lg" animation="glow" /></div>) : (<Section3 data={dataRefSection3.current.body} events={dataRefSection31.current.body} language={language} direction={direction}/>)
        }
        {/* {
          loadingSection4 ? (<div><Placeholder xs={12} size="lg" animation="glow" /></div>) : (<Section4 notify={notify} warning={warning} outlets={outlets} />)
        } */}
        {
          loadingSection5 ? (<div><Placeholder xs={12} size="lg" animation="glow" /></div>) : (<Section5 data={dataRefSection5.current.body} language={language} direction={direction}/>)
        }
        <Section6 notify={notify} warning={warning} language={language} direction={direction}/>
      </div>
      {/* <Alert /> */}
      <Footer language={language} direction={direction}/>
      {/*Notification box configuration*/}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
