import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header({ handleLanguage, language, direction }) {
  const [isActiveMenu, setIsActiveMenu] = useState("home")

  // Get all elements with the class 'scroll-div'
  const divs = document.querySelectorAll('.scroll-div');

  // Function to get the current div's id on scroll
  function getCurrentDivId() {
    let currentDivId = null;

    divs.forEach((div) => {
      const rect = div.getBoundingClientRect();
      const scrollPosition = window.innerHeight / 2; // Adjust the value as needed

      if (rect.top <= scrollPosition && rect.bottom >= scrollPosition) {
        currentDivId = div.id;
      }
    });

    return currentDivId;
  }

  function closeMenu() {
    const element = document.getElementById("toggleMenu")
    var computedStyle = window.getComputedStyle(element);
    if (computedStyle.display !== "none") {
      setTimeout(function () {
        element.click()
      }, 500)
    }
  }
  // Listen for the 'scroll' event on the window
  window.addEventListener('scroll', () => {
    var currentId = getCurrentDivId()
    //let frachise = document.getElementById('frachise')
    if (currentId === "section3" || currentId === "section4") currentId = "section3"
    //if (currentId === "section6" && frachise !== null) frachise.classList.remove("d-none")
    setIsActiveMenu(currentId)
  });
  return (
    <>
      <div className="sticky-top" dir={direction}>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="#">
              <Image src="https://awjftp.blob.core.windows.net/awjcontents/websites/awani/logo.webp" className="header-logo-start" />
            </Navbar.Brand>
            <Navbar.Toggle id="toggleMenu" aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="mx-auto my-2 my-lg-0"
              >
                <Nav.Link href="#home" className={isActiveMenu === "home" ? "text-uppercase nav-link-active"+language+language : "text-uppercase primary"+language} onClick={closeMenu}>{language === "AR" ? 'الرئيسية' : 'Home'}</Nav.Link>
                <Nav.Link href="#section1" className={isActiveMenu === "section1" ? "text-uppercase nav-link-active"+language : "text-uppercase primary"+language} onClick={closeMenu}>{language === "AR" ? 'النبذة' : 'About us'}</Nav.Link>
                <Nav.Link href="#section2" className={isActiveMenu === "section2" ? "text-uppercase nav-link-active"+language : "text-uppercase primary"+language} onClick={closeMenu}>{language === "AR" ? 'قائمة الطعام' : 'Menu'}</Nav.Link>
                <Nav.Link href="#section3" className={isActiveMenu === "section3" ? "text-uppercase nav-link-active"+language : "text-uppercase primary"+language} onClick={closeMenu}>{language === "AR" ? 'المناسبات' : 'Events'}</Nav.Link>
                <Nav.Link href="#section5" className={isActiveMenu === "section5" ? "text-uppercase nav-link-active"+language : "text-uppercase primary"+language} onClick={closeMenu}>{language === "AR" ? 'المواقع' : 'Locations'}</Nav.Link>
                <Nav.Link href="#section6" className={isActiveMenu === "section6" ? "text-uppercase nav-link-active"+language : "text-uppercase primary"+language} onClick={closeMenu}>{language === "AR" ? 'تواصل معنا' : 'Contact us'}</Nav.Link>
                <NavDropdown title={language} id="dropdown-language" className="primary">
                  <NavDropdown.Item onClick={() => handleLanguage("EN")} className="primary">English</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleLanguage("AR")} className="primary">Arabic</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav className="d-flex float-mobile-end">
                <Image src="https://awjftp.blob.core.windows.net/awjcontents/websites/awani/logo-small.webp" className="header-logo-end" />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;